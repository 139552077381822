.label {
  width: 100%;
}

.input {
  width: 100%;
  margin-top: 1rem;
  border: 1px solid #70707070;
  border-radius: 10px;
  padding: 0.5rem 1.5rem;
  background: #f9f9f9;
  color: #464646;
  outline: none;
  appearance: none;
  font-size: 2rem;
}

.input:focus {
  outline: 0.5px solid #464646;
}

.checkbox_env {
  display: grid;
  grid-template-columns: repeat(3, minmax(10rem, 16rem));
  gap: 0 1.5rem;
}

.checkbox {
  color: blue;
}

.checkbox_env label {
  font-size: 2rem;
  font-weight: 400;
  margin: 0;
}

.checkbox_env label {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
