.exclusive {
  background: #1a1a1a;
  padding: 2rem;
  text-align: center;
  color: #fff;
  margin: 6rem 0 0;
}

.exclusive p {
  margin-top: 1rem;
}

@media only screen and (max-width: 1280px) {
  .exclusive p {
    font-size: 2rem;
  }
}
