.media_link {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  color: #fff;
  margin-top: 1.5rem;
}

.media_link img {
  min-width: 3rem;
  max-width: 3rem;
}

.media_link p {
  font-size: 1.6rem;
}
