.social {
  text-align: center;
  padding: 18rem 2rem;
}

.social p {
  margin-top: 1.5rem;
}

.social a {
  background: #373737;
  padding: 0.5rem 2rem;
  max-width: 46rem;
  margin: 1.5rem auto 0;
  color: #fff;
  border-radius: 10px;
  font-size: 2.6rem;
  font-weight: 700;
}

.social_pics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(26rem, auto));
  align-items: center;
  gap: 3rem;
  justify-content: center;
  margin-top: 4rem;
}

.social_pics li img {
  border-radius: 10px;
}
