/* reset */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Inter", sans-serif;
  font-size: 1.6rem;
  line-height: 1.4;
}

ul,
li {
  list-style: none;
}

a {
  display: block;
  text-decoration: none;
}

/* geral */

img {
  display: block;
  max-width: 100%;
}

h1 {
  font-family: "Gentium Basic", sans-serif;
  font-size: 6rem;
  line-height: 1.2;
  font-weight: 700;
}

p {
  font-size: 2.6rem;
}

h2,
h3,
h4 {
  font-family: "Gentium Basic", sans-serif;
}

.grid {
  max-width: 148rem;
  margin: 0 auto;
}

.bold {
  font-weight: 700;
}

.regular {
  font-weight: 400;
}

.underline {
  text-decoration: underline;
}

.active {
  font-size: 3rem;
}

.errorIn {
  font-size: 1.4rem;
  color: yellow;
  opacity: 0;
  transform: translate(-30px, 0);
  animation: inside 0.2s ease forwards;
}

@keyframes inside {
  to {
    margin: 0.6rem 0;
    transform: initial;
    opacity: 1;
  }
}

@keyframes ping {
  to {
    opacity: 1;
  }
}

li a.active {
  background-color: #e5a94f;
}

label {
  display: block;
  font-size: 2.6rem;
  font-weight: 700;
  color: #464646;
  cursor: pointer;
}

input {
  display: block;
}

textarea {
  display: block;
}

.submit_button {
  display: block;
  width: 100%;
  max-width: 24rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.5rem 1rem;
  font-size: 2.6rem;
  font-family: "Inter", sans-serif;
  color: #fff;
  background: #373737;
  transition: background 0.2s ease;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
}

.submit_button.center {
  margin: 2rem auto;
}

.submit_button:focus {
  outline: 1px solid black;
}

.submit_button:hover {
  background: #e5a94f;
}

.submit_button:disabled {
  background: gray;
  cursor: not-allowed;
}

.loading {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #373737;
  animation: ping 1.4s ease infinite;
  margin-left: 0.5rem;
  opacity: 0;
}

.show {
  opacity: 0;
  transform: translateX(-3rem);
  animation: showIn 0.4s ease forwards;
}

@keyframes showIn {
  to {
    opacity: 1;
    transform: translate(0);
  }
}

@media only screen and (max-width: 540px) {
  h1 {
    font-size: 4rem;
  }

  p {
    font-size: 2rem;
  }
}
