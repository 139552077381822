.newsletter_section {
  padding: 0 2rem 4rem;
}

.newsletter {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5rem;
  padding: 3rem 6rem;
  background: #1a1a1a;
  margin: 7rem auto 0;
  color: #fff;
}

.newsletter_text {
  max-width: 58rem;
}

.newsletter_text p {
  margin-top: 1rem;
}

.newsletter_text p:nth-child(3) {
  text-decoration: underline;
}

@media only screen and (max-width: 1200px) {
  .newsletter {
    align-items: flex-start;
    flex-direction: column;
    gap: 3rem;
  }

  .newsletter_text {
    max-width: 100%;
  }
}

@media only screen and (max-width: 560px) {
  .newsletter {
    padding: 3rem 2rem;
  }

  .newsletter_text p {
    font-size: 2rem;
  }
}
