.benefits {
  padding: 0 4rem 8rem;
}

.benefits h1 {
  text-align: center;
}

.benefits ul {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 4rem;
}

.benefits ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  width: calc(25% - 2rem);
  flex-grow: 1;
  border: 1px solid #707070;
  padding: 1rem 2rem;
  border-radius: 5px;
}

.benefits ul li div p:nth-child(1) {
  font-family: "Gentium Basic", sans-serif;
  font-weight: 700;
  line-height: 1;
}

.benefits ul li div p:nth-child(2) {
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 1rem;
}

.benefits svg {
  max-width: 5rem;
  min-width: 3rem;
}

@media only screen and (max-width: 1280px) {
  .benefits ul li {
    width: calc(50% - 2rem);
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 680px) {
  .benefits ul li {
    width: 100%;
  }
}

@media only screen and (max-width: 560px) {
  .benefits {
    padding: 0 2rem 8rem;
  }
}
