.about_wrapper {
  display: grid;
  grid-template-columns: minmax(50rem, 58rem) minmax(30rem, 70rem);
  padding: 8rem 2rem 12rem;
  gap: 8rem;
  justify-content: space-between;
}

.about_wrapper img {
  object-fit: cover;
  border-radius: 10px;
}

.about_wrapper_text h1 {
  margin-bottom: 4rem;
}

.about_wrapper_text p {
  color: #464646;
}

.about_wrapper_text p + p {
  margin-top: 4rem;
}
@media only screen and (max-width: 1200px) {
  .about_wrapper {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}
