.contact_pics {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  width: 50%;
}

.contact_pics img {
  border-radius: 10px;
  width: calc(50% - 2rem);
  max-height: 47rem;
  object-fit: cover;
  object-position: center;
}

@media only screen and (max-width: 1300px) {
  .contact_pics {
    width: 100%;
  }
}

@media only screen and (max-width: 580px) {
}
