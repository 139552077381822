.author {
  padding: 14rem 4rem;
  border-top: 1px solid #373737;
  border-bottom: 1px solid #373737;
}

.author_section {
  display: flex;
  gap: 6rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

.author_section > div {
  width: calc(50% - 6rem);
}

.author_section img {
  width: 100%;
  max-width: 58rem;
  max-height: 64rem;
  justify-self: flex-end;
  border-radius: 10px;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.author_section div p {
  margin-top: 6rem;
}

@media only screen and (max-width: 1280px) {
  .author_section {
    flex-direction: column-reverse;
    align-items: center;
  }

  .author_section > div {
    width: 100%;
  }
}

@media only screen and (max-width: 560px) {
  .author {
    padding: 14rem 2rem;
  }

  .author_section img {
    min-height: 48rem;
  }
}
