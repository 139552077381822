nav {
  position: relative;
}

.navMenu {
  display: flex;
  max-width: 20rem;
  padding: 0.5rem 4rem;
  color: #fff;
  background: #373737;
  border-radius: 10px;
  font-size: 2.6rem;
  align-items: baseline;
  gap: 1rem;
  cursor: pointer;
  transition: background 0.2s ease-out;
  position: relative;
  user-select: none;
}

.navMenu:hover {
  background: #6d6d6d;
}

.line {
  display: block;
  width: 30px;
  height: 5px;
  background: #fff;
  border-radius: 10px;
}

.line + .line {
  margin-top: 0.5rem;
}

@media only screen and (max-width: 560px) {
  .navMenu {
    font-size: 2rem;
    padding: 0.5rem 1.5rem;
  }
}
