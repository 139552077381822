.login_form {
  max-width: 70rem;
  text-align: center;
  box-shadow: 0 0 99px rgba(0, 0, 0, 0.2);
  padding: 4rem 2rem;
  border-radius: 10px;
}

.login_form h1 {
  margin-bottom: 2rem;
}

.login_form p {
  font-size: 2rem;
  margin-bottom: 3rem;
  color: #464646;
}

.login_form input {
  display: block;
  font-family: "Inter", sans-serif;
  font-size: 2rem;
  width: 100%;
  max-width: 46rem;
  outline: none;
  border: 1px solid #707070;
  border-radius: 10px;
  margin: 0 auto 1rem;
  padding: 0.5rem 1.5rem;
}

.login_form button {
  width: 100%;
  max-width: 46rem;
  background: #373737;
  font-family: "Inter", sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 0.5rem 1.5rem;
  appearance: none;
  outline: none;
  cursor: pointer;
  transition: background 0.2s ease;
}

.login_form button:hover {
  background: #6d6d6d;
}

.login_form img {
  max-width: 17rem;
  margin: 2rem auto 0;
}
