.container {
  padding: 10rem 2rem;
}

.container img {
  max-width: 24rem;
  margin: 0 0 4rem 3rem;
}

.dash {
  display: grid;
  grid-template-columns: minmax(30rem, 40rem) 1fr;
}
