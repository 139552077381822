.catalogo_item {
  max-width: 48rem;
}

.catalogo_item h2 {
  font-size: 4rem;
  text-align: center;
  text-transform: capitalize;
}

.catalogo_item img {
  width: 100%;
  border-radius: 10px;
}

.catalogo_item .catalogo_item_link {
  background: #373737;
  border-radius: 10px;
  width: 100%;
  padding: 1rem 0.5rem;
  margin-top: 1rem;
  color: #fff;
  font-size: 3.2rem;
  font-weight: 700;
}

@media only screen and (max-width: 1280px) {
  .catalogo_item .catalogo_item_link {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 560px) {
  .catalogo_item .catalogo_item_link {
    padding: 0.5rem 1rem;
    font-size: 2rem;
  }
}
