.header {
  padding: 1rem;
  background-color: #1a1a1a;
  width: 100%;
}

.header > div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 2rem;
  justify-items: flex-end;
}

.logo {
  width: 100%;
  min-width: 18rem;
  max-width: 20rem;
  justify-self: start;
  align-self: center;
  grid-column: 2;
}

@media only screen and (max-width: 768px) {
  .header > div {
    grid-template-columns: repeat(2, 1fr);
  }
  .logo {
    grid-column: 1;
  }
}
