.form {
  width: 100%;
  max-width: 46rem;
}

.submit {
  appearance: none;
  outline: none;
  width: 100%;
  max-width: 46rem;
  padding: 0.5rem;
  background: #e5a94f;
  font-family: inherit;
  font-weight: 700;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 2rem;
  font-size: 2rem;
  color: #000000;
  transition: background 0.2s ease;
}

.submit:hover {
  background: #d39230;
}

@media only screen and (max-width: 768px) {
  .form {
    max-width: 100%;
  }
}
