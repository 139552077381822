.create_form {
  margin-top: 6rem;
}

.create_form label {
  margin-top: 4rem;
}

.create_form label + input {
  margin-top: 1.5rem;
}

.label_text {
  font-size: 1.6rem;
  font-weight: 400;
  margin: 1.5rem 0;
}

.checkbox_text {
  font-size: 2.6rem;
  color: #464646;
  margin-top: 4rem;
}

.submit {
  margin-top: 6rem;
}

.image_edit {
  max-width: 30rem;
}
