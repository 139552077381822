.produto {
  padding: 2rem;
}

.banner {
  width: 100%;
  margin: 8rem 0;
  padding: 0.5rem 1rem;
  color: #fff;
  background: #1a1a1a;
  text-align: center;
}

.tip {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;
  max-width: 95rem;
  margin: 0 auto;
  flex-wrap: wrap;
}

.tip li {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  max-width: 45.8rem;
  border: 1px solid #373737;
  border-radius: 10px;
  padding: 2rem;
  margin: 0 auto 5rem;
}

.tip p {
  font-weight: 700;
}

.tip .tip_regular {
  font-size: 1.4rem;
  font-weight: 400;
}

.tip svg {
  min-width: 4rem;
  max-width: 5rem;
  max-height: 5rem;
}

.filter {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}

.filter p {
  color: #373737;
  font-weight: 700;
}

.button_zap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  max-width: 45rem;
  margin: 0 0 0 2rem;
  padding: 1rem;
  background: #19a84e;
  border-radius: 10px;
  color: #fff;
  font-size: 2.6rem;
  font-weight: 700;
}

.select {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.select select {
  width: 30rem;
  font-size: 2rem;
  padding: 1rem;
  background: #f6f6f6;
  border: 1px solid #707070;
  border-radius: 10px;
  cursor: pointer;
}

.filter select > option {
  border-radius: 10px;
  padding: 0.5rem;
}

.product_env {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 33rem));
  justify-content: center;
  gap: 3rem;
  margin-top: 6rem;
  padding: 6rem 0;
}

.product {
  background: #373737;
  border-radius: 10px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);
}

.product .specs {
  color: #fff;
  padding: 2rem;
}

.specs_title {
  font-family: "Gentium Basic", sans-serif;
  font-size: 4rem;
  font-weight: 700;
}

.specs_sub {
  font-size: 1.4rem;
}

.specs_sub + .specs_sub {
  font-family: "Inter", sans-serif;
  font-weight: light;
  margin-top: 1rem;
}

.specs_sub .style_span {
  font-size: 1.6rem;
  font-weight: 700;
}

.risco {
  text-decoration: line-through;
}

.product img {
  border-radius: 10px;
  width: 100%;
  min-height: 40rem;
  max-height: 40rem;
  object-fit: cover;
  max-height: 30rem;
}

@media only screen and (max-width: 1200px) {
  .filter {
    flex-direction: column;
    gap: 3rem;
  }

  .button_zap {
    order: -1;
    margin: 0;
  }
}

@media only screen and (max-width: 780px) {
  .button_zap {
    font-size: 2rem;
  }

  .select {
    gap: 1rem;
  }

  .select select {
    width: 18rem;
  }

  .tip li:nth-of-type(1) {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 480px) {
  .tip svg {
    min-width: 4rem;
    max-width: 4rem;
    max-height: 4rem;
  }

  .static_img {
    min-height: 24rem;
    object-fit: cover;
    object-position: 70%;
  }
}
