.contact {
  background: #373737;
  color: #fff;
  padding: 10rem 2rem;
}

.contact_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 3rem;
  padding: 0 0 2rem;
}

.contact svg {
  max-width: 22rem;
}

@media only screen and (max-width: 1300px) {
  .contact_content {
    flex-direction: column;
  }
}
