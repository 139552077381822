.header_user {
  padding: 1rem;
  background-color: #1a1a1a;
  width: 100%;
}

.header_user > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
}

.logo {
  width: 100%;
  max-width: 20rem;
  min-width: 18rem;
}

.link {
  color: #fff;
  border: 1px solid #fff;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  transition: all 0.2s ease;
}

.painel {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.painel button {
  font-family: "Inter", sans-serif;
  font-size: 1.6rem;
  outline: none;
  appearance: none;
  border: 1px solid #fff;
  padding: 0.5rem 1rem;
  background: none;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.link:hover,
.painel button:hover {
  background: #fff;
  color: #1a1a1a;
}
