.editar {
  margin-top: 6rem;
}

.produto + .produto {
  margin-top: 3rem;
}

.title {
  font-size: 3.2rem;
  font-weight: 700;
  color: #464646;
}
.buttons {
  display: flex;
  gap: 2rem;
}

.red {
  background: rgb(104, 0, 0);
}

.red:hover {
  background: rgb(204, 1, 1);
}
