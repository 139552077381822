.editor {
  margin-left: 7rem;
}

.link {
  display: inline-block;
  padding: 0.5rem 1rem;
  width: 100%;
  max-width: 14rem;
  text-align: center;
  background: #373737;
  border-radius: 10px;
  color: #fff;
  transition: background 0.2s ease;
}

.link:hover {
  background: #e5a94f;
}

.link + .link {
  margin: 2rem 0 0 1.5rem;
}
