.menu_list {
  display: block;
  padding: 0.5rem 3rem;
  background: #fff;
  color: #373737;
  font-size: 2.6rem;
  position: absolute;
  top: calc(100% + 15px);
  right: 0;
  z-index: 10;
  width: 42rem;
  border-radius: 10px;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.3);
}

.menu_list li {
  border-bottom: 1px solid #373737;
  padding: 1.2rem 0;
  text-align: right;
  transition: background 0.2s ease;
}

.menu_list li:hover {
  background: #f1efef;
  border-radius: 10px;
}

.menu_list a {
  color: #373737;
}

@media only screen and (max-width: 768px) {
  .menu_list {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 480px) {
  .menu_list {
    font-size: 1.8rem;
    width: 32rem;
  }
}

@media only screen and (max-width: 375px) {
  .menu_list {
    max-width: 30rem;
  }
}
