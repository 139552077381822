.textarea {
  margin-top: 2rem;
  font-size: 2rem;
  font-family: "Inter", sans-serif;
  padding: 1.5rem;
  color: #464646;
  border: 1px solid #70707070;
  border-radius: 10px;
  background: #f9f9f9;
}

.textarea:focus {
  outline: 0.5px solid #464646;
}
