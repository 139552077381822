.social_media {
  margin-top: 6rem;
}
.social_media a p {
  font-size: 1.4rem;
}

@media only screen and (max-width: 375px) {
  .social_media a p {
    font-size: 1.4rem;
  }
}
