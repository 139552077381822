.address {
  width: calc(50% - 3rem);
}

.address .address_block:nth-of-type(1) h1 {
  margin-bottom: 4rem;
}

.address_block + .address_block {
  margin-top: 4rem;
}

.address_block p {
  margin-top: 1rem;
}

.address_block a {
  color: #fff;
  text-decoration: underline;
  font-size: 2.6rem;
}

@media only screen and (max-width: 1300px) {
  .address {
    width: 100%;
  }
}
