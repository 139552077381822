.page_form label + label {
  margin-top: 4rem;
}

.submit_button {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 24rem;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.5rem 1rem;
  font-size: 2.6rem;
  font-family: "Inter", sans-serif;
  color: #fff;
  background: #373737;
  transition: background 0.2s ease;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
}

.submit_button:focus {
  outline: 1px solid black;
}

.submit_button:hover {
  background: #e5a94f;
}

.submit_button:disabled {
  background: gray;
  cursor: not-allowed;
}
