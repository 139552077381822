.buttonUp {
  appearance: none;
  position: fixed;
  width: 100%;
  max-width: 45.8rem;
  background: #1a1a1a;
  color: #fff;
  bottom: 20rem;
  right: 0;
  font-family: "Inter", sans-serif;
  text-align: left;
  border-radius: 10px 0 0 10px;
  outline: none;
  border: none;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);
  padding: 0.3rem 1rem;
  margin-left: 5rem;
  cursor: pointer;
}

.buttonUp p {
  font-size: 2rem;
  margin-left: 5rem;
}

@media only screen and (max-width: 768px) {
  .buttonUp {
    max-width: 30rem;
    bottom: 10rem;
  }
}

@media only screen and (max-width: 540px) {
  .buttonUp {
    max-width: 26rem;
    bottom: 8rem;
  }
}
