.editor_container {
  border-right: 1px solid #373737;
}

.editor_link {
  max-width: 33.6rem;
}

.editor_link a {
  font-size: 2.6rem;
  background: #373737;
  border-radius: 10px;
  padding: 1.5rem 1rem;
  text-align: center;
  color: #fff;
  width: 100%;
  margin-top: 2rem;
  transition: background 0.2s ease;
}

.editor_link a:hover {
  background: #e5a94f;
}

.active {
  background: yellow;
}
