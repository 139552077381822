.catalogo {
  padding: 14rem 2rem 0;
}

.catalogo h1,
.catalogo p {
  text-align: center;
}

.catalogo_lista {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6rem 3rem;
  padding: 6rem 0;
}

@media only screen and (max-width: 1000px) {
  .catalogo_lista {
    grid-template-columns: repeat(2, minmax(20rem, 45rem));
  }
}

@media only screen and (max-width: 560px) {
  .catalogo_lista {
    grid-template-columns: 1fr;
  }
}
