.instrucoes {
  margin-left: 7rem;
}

.instrucoes h1 {
  margin-bottom: 5rem;
}

.instrucoes p {
  color: #464646;
}

.instrucoes p + p {
  margin-top: 4rem;
}
