.component {
  margin-left: 7rem;
}

.component h1 {
  margin-bottom: 5rem;
}

.person_env {
  margin-top: 2rem;
}

.person {
  display: flex;
  align-items: center;
  gap: 2rem;
  border: 1px solid #e5a94f;
  border-radius: 10px;
  padding: 3rem 2rem;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  user-select: none;
}

.person + .person {
  margin-top: 2rem;
}

.person_title {
  font-weight: 700;
}

.separador {
  display: block;
  height: 30px;
  width: 1px;
  background: #373737;
}

.buttons_env {
  display: flex;
  gap: 2rem;
}

.person_env button {
  appearance: none;
  outline: none;
  background: none;
  padding: 0.5rem 1rem;
  font-size: 2rem;
  background: #1a1a1a;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.person_env button:hover {
  background: #e5a94f;
}
