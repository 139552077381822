.newsInput {
  display: block;
  width: 100%;
  background: #373737;
  border: 1px solid #ffffff;
  padding: 1rem 2rem;
  outline: none;
  border-radius: 5px;
  color: #fff;
  opacity: 0.8;
  font-size: 2rem;
}

.newsInput::placeholder {
  color: #fff;
}

.newsInput + .newsInput {
  margin-top: 2rem;
}

.error {
  font-size: 1.4rem;
  color: yellow;
  opacity: 0;
  transform: translate(0, -15px);
  animation: inside 0.2s ease forwards;
}

@keyframes inside {
  to {
    margin: 0.6rem 0;
    transform: initial;
    opacity: 1;
  }
}

@media only screen and (max-width: 520px) {
  .newsInput {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 375px) {
  .newsInput {
    font-size: 1.4rem;
  }
}
